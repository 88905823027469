import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    chargingstation:undefined,
    chargingstations:undefined,
});

const getters = {
    allChargingStation:(state)=>state.chargingstation,
    allChargingStations:(state)=>state.chargingstations,
};

const actions = {
    async postChargingStation({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}chargingstation/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setChargingStation", response);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getChargingStationList({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}chargingstation/chargingStationList`, data,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setChargingStations", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleChargingStation({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}chargingstation/singleChargingStation/${data}`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setChargingStation", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getChargingStationFrontend({commit},data){
        try {
            const response = await Axios.post(`${baseUrl}chargingstation/chargingStationFrontend`, data);
            if (response.status == 200) {
                commit("setChargingStations", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteChargingStation({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}chargingstation/singleChargingStation/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setChargingStations", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setChargingStation: (state, data) => {
        return state.chargingstation = data;
    },
    setChargingStations: (state, data) => {
        return state.chargingstations = data;
    },
};

export default { state, getters, actions, mutations };
