<template>
  <header class="mainHeader" uk-sticky v-if="status">
    <div class="topMenu">
      Do you have any confusion? Call for consultation (<a
        href="tel:+9779851320368"
        >9851320368</a
      >)
    </div>
    <section class="navBox">
      <v-container class="container-custom">
        <v-row class="borderBottom">
          <v-col md="5" class="py-0">
            <div class="headerSection">
              <router-link to="/"
                ><img src="@/assets/logo.png" alt="" />
              </router-link>
              <SpinningWheel />
            </div>
          </v-col>
          <v-col md="4" class="py-0 searchBox web-screen">
            <v-text-field
              @keyup="searchAction"
              v-model="search"
              outlined
              placeholder="Search by Make, Model or Keyword"
              append-icon="mdi-magnify"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col md="1" class="py-0 text-center cartBoxRel web-screen">
            <div class="relIcon">
              <v-icon @click="cartAction"> mdi-cart-outline </v-icon>
              <span class="cartNumber" v-if="loading"
                ><v-progress-circular indeterminate></v-progress-circular
              ></span>
              <span class="cartNumber" v-if="!loading && allCarts">{{
                allCarts.products.length
              }}</span>
              <div class="catBox" v-if="cartShow && allCarts">
                <ul>
                  <li
                    v-for="(data, index) in allCarts.results"
                    :key="index + 'cart'"
                  >
                    <div
                      v-for="(dat, index) in data.products"
                      :key="index + 'cartSmall'"
                    >
                      <CartItemSmall :item="dat" />
                    </div>
                  </li>
                </ul>
                <div class="text-left" v-if="allCarts.results.length == 0">
                  No Product Added To Cart
                </div>
                <ul class="cartFooter" v-if="allCarts.results.length != 0">
                  <li>
                    <router-link to="/cart">
                      <v-btn class="primaryBtn" @click="cartAction"
                        >View Cart <v-icon> mdi-cart-outline </v-icon></v-btn
                      >
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/checkout">
                      <v-btn @click="cartAction">
                        Check Out <v-icon> mdi-chevron-double-right </v-icon>
                      </v-btn>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
          <v-col md="2" class="py-0 backColor">
            <span v-if="!user">
              <v-icon>mdi-account-outline</v-icon>
              <router-link to="/login">Login</router-link> <b>/</b>
              <router-link to="/registration">Sign up</router-link>
            </span>
            <div class="userLogged" v-if="user">
              <div class="intialBox">
                {{ initials }}
              </div>
              <span class="accountName">{{ user.fullname }}</span>
              <ul class="dropdown">
                <li>
                  <router-link to="/account">Profile Setting</router-link>
                </li>
                <li>
                  <router-link to="/savedcars"
                    >Saved Cars
                    <strong
                      class="numberBox"
                      v-if="!loading && allWishlists.results"
                      >{{ allWishlists.results.length }}</strong
                    ></router-link
                  >
                </li>
                <li><router-link to="/orders">My Order</router-link></li>
                <li
                  v-if="
                    user.role == 'admin' ||
                    user.role == 'superadmin' ||
                    user.role == 'business'
                  "
                >
                  <router-link to="/business">Dashboard</router-link>
                </li>
                <li><span @click="signOut">Sign Out</span></li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="whiteBack">
      <v-container class="container-custom web-screen">
        <v-row>
          <v-col md="12" class="py-0">
            <div class="backBox">
              <ul class="frontendMenu" v-if="menuLoading">
                <li v-for="n in 8" :key="n + 'menuload'">
                  <v-skeleton-loader
                    class="mx-auto width100"
                    height="20"
                    type="image"
                  ></v-skeleton-loader>
                </li>
              </ul>
              <ul class="frontendMenu" v-if="!menuLoading">
                <li v-for="(data, index) in menus" :key="index + 'mainMenu'">
                  <router-link :to="data.link"
                    >{{ data.menuName }}
                    <v-icon v-if="data.subMenu.length > 0">
                      mdi-chevron-down
                    </v-icon></router-link
                  >
                  <ul class="dropdown" v-if="data.subMenu.length > 0">
                    <li
                      v-for="(dat, index) in data.subMenu"
                      :key="index + 'subMenu'"
                    >
                      <router-link :to="dat.link">{{
                        dat.menuName
                      }}</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mobile-screen">
        <v-row>
          <v-col cols="9">
            <div class="d-flex">
              <v-icon @click="menuNavToggleAct" class="pr-2"> mdi-menu </v-icon>
              Menu
            </div>
          </v-col>
          <v-col cols="3" class="py-0 text-center cartBoxRel">
            <div class="relIcon">
              <v-icon @click="cartAction"> mdi-cart-outline </v-icon>
              <span class="cartNumber" v-if="loading"
                ><v-progress-circular indeterminate></v-progress-circular
              ></span>
              <span class="cartNumber" v-if="!loading && allCarts">{{
                allCarts.products.length
              }}</span>
              <div class="catBox" v-if="cartShow && allCarts">
                <ul>
                  <li
                    v-for="(data, index) in allCarts.results"
                    :key="index + 'cart'"
                  >
                    <div
                      v-for="(dat, index) in data.products"
                      :key="index + 'cartSmall'"
                    >
                      <CartItemSmall :item="dat" />
                    </div>
                  </li>
                </ul>
                <div class="text-left" v-if="allCarts.results.length == 0">
                  No Product Added To Cart
                </div>
                <ul class="cartFooter" v-if="allCarts.results.length != 0">
                  <li>
                    <router-link to="/cart">
                      <v-btn class="primaryBtn" @click="cartAction"
                        >View Cart <v-icon> mdi-cart-outline </v-icon></v-btn
                      >
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/checkout">
                      <v-btn @click="cartAction">
                        Check Out <v-icon> mdi-chevron-double-right </v-icon>
                      </v-btn>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
          <v-col md="12" cols="12" class="pt-0" v-if="menuToggleMobile">
            <div class="backBox">
              <ul class="frontendMenu" v-if="menuLoading">
                <li v-for="n in 8" :key="n + 'menuload'">
                  <v-skeleton-loader
                    class="mx-auto width100"
                    height="20"
                    type="image"
                  ></v-skeleton-loader>
                </li>
              </ul>
              <ul class="frontendMenu" v-if="!menuLoading">
                <li v-for="(data, index) in menus" :key="index + 'mainMenu'">
                  <router-link :to="data.link"
                    >{{ data.menuName }}
                    <v-icon v-if="data.subMenu.length > 0">
                      mdi-chevron-down
                    </v-icon></router-link
                  >
                  <ul class="dropdown" v-if="data.subMenu.length > 0">
                    <li
                      v-for="(dat, index) in data.subMenu"
                      :key="index + 'subMenu'"
                    >
                      <router-link :to="dat.link">{{
                        dat.menuName
                      }}</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <div class="hiddenBox2" v-if="cartShow" @click="cartAction"></div>
    <div class="hiddenBox" v-if="searchBar" @click="searchActionClick"></div>
    <div class="searchBoxHeader" v-if="searchBar">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" v-if="!searchLoading">
            <div v-if="searches.products.length == 0">
              <h1>No Product Found</h1>
            </div>
            <div>
              <ul class="menuList">
                <li
                  v-for="(data, index) in searches.products"
                  :key="index + 'product'"
                >
                  <header>
                    <span>Poplular {{ data._id }}</span>
                    <router-link :to="`/search?category=${data._id}`">
                      View All <v-icon> mdi-chevron-double-right </v-icon>
                    </router-link>
                  </header>
                  <ul v-if="data.items != undefined">
                    <li v-for="(dat, ind) in data.items" :key="ind + 'pro'">
                      <router-link :to="`/product/${dat._id}`">{{
                        dat.itemName
                      }}</router-link>
                    </li>
                  </ul>
                </li>
              </ul>

              <v-row class="blogCoummunity">
                <v-col md="4">
                  <div
                    v-for="(data, index) in searches.blogs"
                    :key="index + 'product'"
                  >
                    <header>
                      <span>Poplular Blogs</span>
                      <router-link to="/blogs">
                        View All <v-icon> mdi-chevron-double-right </v-icon>
                      </router-link>
                    </header>
                    <ul class="blogLi" v-if="data.items != undefined">
                      <li v-for="(dat, ind) in data.items" :key="ind + 'blog'">
                        <BlogList :item="dat" />
                      </li>
                    </ul>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col md="12" v-if="searchLoading">
            <Loading />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </header>
</template>

<script>
import CartItemSmall from "@/components/CartItemSmall";
import SpinningWheel from "@/components/user/SpinningWheel";
import BlogList from "@/components/BlogList";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  data: () => ({
    menuStatus: false,
    cartShow: false,
    logged: true,
    searchBar: false,
    search: "",
    user: undefined,
    initials: undefined,
    loading: true,
    menuLoading: true,
    searchLoading: false,
    menus: [],
    searches: [],
    searchTwoProducts: [],
    searchThreeProducts: [],
    searchFourProducts: [],
    searchCommercialProducts: [],
    searchVideos: [],
    menuToggleMobile: false,
        status:true
  }),
  components: {
    CartItemSmall,
    BlogList,
    Loading,
    SpinningWheel,
  },
  computed: {
    ...mapGetters([
      "allLogin",
      "allCarts",
      "allWishlists",
      "allMenus",
      "allSearches",
    ]),
  },
  methods: {
    ...mapActions([
      "logout",
      "getLogin",
      "getCarts",
      "getWishList",
      "getFrontendMenus",
      "getSearches",
    ]),
    menuToggle() {
      this.menuStatus = !this.menuStatus;
    },
    menuNavToggleAct() {
      this.menuToggleMobile = !this.menuToggleMobile;
    },
    cartAction() {
      this.cartShow = !this.cartShow;
    },
    searchActionClick() {
      this.searchBar = false;
    },
    async searchAction() {
      if (this.search.length > 0) {
        this.searchLoading = true;
        this.searchBar = true;
        await this.getSearches({
          searchKeyword: this.search,
        });
        this.searches = this.allSearches.results;
        this.searchLoading = false;
      } else {
        this.searchBar = false;
      }
    },
    signOut() {
      this.logout();
      this.$router.push("/login");
    },
  },
  async created() {
    if (this.$route.query.header != undefined) {
      this.status = false;
    } else {
      this.status = true;
    }
    if (this.allMenus == undefined) {
      await this.getFrontendMenus("header");
    }
    this.menus = this.allMenus.results;

    this.menuLoading = false;
    this.getLogin();
    if (this.allLogin) {
      this.user = this.allLogin.user;
      this.initials = this.user.fullname[0];
      if (this.user.fullname.split(" ").length > 1) {
        this.initials += this.user.fullname.split(" ")[1][0];
      }
      await this.getCarts();
      await this.getWishList();
    }

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.mainHeader {
  box-shadow: 0 1px 2px #efefef;
  position: relative;
}
.whiteBack {
  background: #fff;
}
.navBox {
  position: relative;
  z-index: 9991;
  background: #fff;
}
.headerSection {
  display: flex;
  align-items: center;
  img {
    width: 200px;
  }
}
.blogLi {
  list-style: none;
  padding-left: 0;
  li {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.borderBottom {
  position: relative;
  align-items: center;
  // overflow: hidden;
  &::after {
    position: absolute;
    content: "";
    left: -100%;
    right: -100%;
    height: 1px;
    background: #ededed;
    bottom: 0;
  }
}
.searchBox {
  .v-text-field {
    margin-top: 15px;
    margin-bottom: -10px;
  }
}

.backColor {
  position: relative;
  color: #fff;
  .v-icon {
    color: #fff;
  }
  .v-icon {
    margin-right: 5px;
    font-size: 18px;
  }
  a {
    font-size: 15px;
    color: #fff;
    margin: 0 5px;
    text-decoration: none;
  }
  span {
    position: relative;
    font-size: 15px;
    z-index: 2;
    margin: 0 5px;
    width: 100%;
    text-align: center;
    display: block;
  }
  background: #0878d4;
  height: 91px;
  display: flex;
  align-items: center;
  justify-content: center;
}
b {
  display: inline-block;
  padding: 0 5px;
}
.frontendMenu {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    position: relative;
    padding: 15px;
    text-align: left;

    &:hover {
      .dropdown {
        display: block;
      }
    }
  }
  & > li {
    &:first-of-type {
      padding-left: 0;
    }
  }
  a {
    color: #000;
    text-decoration: none;
    font-size: 14px;
  }
}
.numberBox {
  display: inline-flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  background: #0878d4;
  border-radius: 50%;
  color: #fff;
  margin-left: 75px;
}
.dropdown {
  list-style: none;
  padding-left: 0;
  border: 2px solid #ededed;
  position: absolute;
  background: #fff;
  z-index: 999;
  top: 100%;
  margin-left: -10px;
  display: none;
  li {
    width: 200px;
    padding: 8px;
  }
}
a {
  text-decoration: none;
}
.relIcon {
  position: relative;
  @media only screen and (max-width: 991px) {
    padding-top: 10px;
  }
  .v-icon {
    color: #0878d4;
  }
  .cartNumber {
    position: absolute;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    right: 20px;
    background: #0878d4;
    font-size: 14px;
    color: #fff;
    border-radius: 50%;
    @media only screen and (max-width: 991px) {
      top: 0;
      right: 20px;
    }
  }
}
.hiddenBox {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
.hiddenBox2 {
  position: fixed;
  background: transparent;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.cartBoxRel {
  position: relative;
  .catBox {
    position: absolute;
    border: 2px solid #ededed;
    z-index: 99999;
    background: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    width: 500px;
    right: 20px;
    top: 30px;
    padding-top: 20px;
    @media only screen and (max-width: 991px) {
      right: -10px;
      top: 40px;
      width: 450px;
    }
    ul {
      list-style: none;
      padding-left: 0;
      li {
        width: 100%;
        border: 1px solid #ecdfdf;
        padding: 20px 20px 0;
        margin-bottom: 10px;
        border-radius: 5px;
      }
    }
    .cartFooter {
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin: 0 -10px;
      li {
        padding: 10px;
        border: none;
      }
    }
    .v-btn {
      width: 100% !important;
      min-height: 60px;
      background: #fff !important;
      color: #0878d4 !important;
      border: 2px solid #0878d4 !important;
      box-shadow: none !important;
      .v-icon {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    .primaryBtn {
      background: #0878d4 !important;
      color: #fff !important;
      .v-icon {
        color: #fff;
      }
    }
  }
}
.cartDetail {
  display: flex;
  margin-top: 15px !important;
  li {
    padding: 0 !important;
    border: none !important;
    text-align: left;
    text-transform: uppercase;
    color: #8c8c8c;
    &:last-of-type {
      text-align: right;
    }
  }
}
.userLogged {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
  position: relative;
  &:hover {
    .dropdown {
      top: 40px;
      display: block;
      li {
        a,
        span {
          color: #000;
          text-align: left;
          cursor: pointer;
        }
      }
    }
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    // margin-left: 20px;
    border: 1px solid #fff;
  }
}
.width100 {
  width: 120px;
}
.searchBoxHeader {
  position: absolute;
  top: 90px;
  z-index: 99;
  background: #fff;
  width: 100%;
  padding: 20px 0;
  .menuList {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;
    font-size: 14px;
    li {
      padding: 0 10px;
      width: 100%;
      ul {
        padding-left: 0;
        li {
          padding: 10px 0;
          list-style: none;
          a {
            color: #000;
            text-decoration: underline !important;
          }
        }
      }
    }
    .v-icon {
      font-size: 18px;
      color: #0878d4;
      margin-top: -3px;
    }
    hr {
      color: #efefef;
    }
    header {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      padding: 10px 0;
      border: 0 !important;
      box-shadow: none !important;
      a {
        font-size: 14px;
      }
    }
  }
}
.blogCoummunity {
  border-top: 1px solid #efefef;
  margin-top: 10px;
  header {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    box-shadow: none;
    span {
      font-weight: 500;
      font-size: 16px;
    }
    .v-icon {
      font-size: 18px;
      color: #0878d4;
      margin-top: -3px;
    }
  }
}
.uk-sticky {
  margin-top: 0;
  z-index: 999;
}
.accountName {
  text-transform: capitalize;
}
.intialBox {
  text-transform: uppercase;
  height: 40px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #fff;
  font-weight: 500;
  margin-right: 5px;
}
.web-screen {
  @media only screen and (max-width: 991px) {
    display: none;
  }
}
.mobile-screen {
  @media only screen and (min-width: 991px) {
    display: none;
  }
  .frontendMenu {
    flex-flow: column;

    li {
      padding: 10px;
    }
  }
}
.topMenu {
  width: 100%;
  background: #274e87;
  text-align: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.2px;
  a {
    color: #fff;
    text-decoration: underline !important;
  }
}
</style>