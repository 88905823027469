import Axios from "axios";
import router, { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    community: undefined,
});


const getters = {
    allCommunities: (state) => state.carts,
};

const actions = {
    async postCommunity({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}cart/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setCommunities", response.data);
            }
        } catch (error) {
            if(error.response != undefined){
                commit("setCommunities", error.response.data);
            }else{
                
                commit("setCommunities", {results:[],message:"Please login first"});
                setTimeout(()=>{
                    router.push("/login")
                },1000)
         
            }
           
        }
    },
    async getCommunities({ commit },data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}community/commentListing`,data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setCommunities", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteCommunity({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}cart/singleCart/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setCommunities", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
   
}

const mutations = {
    setCommunities: (state, data) => {
        return state.carts = data;
    },
};

export default { state, getters, actions, mutations };
