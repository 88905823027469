<template>
  <v-app id="app">
    <router-view :key="$route.fullPath"></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
};
</script>

<style lang="scss">
.v-rating .v-icon {
  padding: 0.1rem !important;
}
.systemTitle {
  font-size: 25px;
  padding: 20px 0;
}
.tabBox {
  box-shadow: 0 1px 2px #efefef;
  padding: 20px;
  background: linear-gradient(to top right, #1ea333 10%, #1ea33236) !important;
  border-radius: 5px;
  color: #fff;
  h1 {
    font-size: 40px;
    color: #fff;
  }
}
.greenBack {
  background: #0878d41a;
  th {
    .text-left {
      font-size: 14px !important;
      text-align: left;
      padding: 10px;
    }
  }
}
.v-skeleton-loader {
  border-radius: 0 !important;
}
.cursorIcon {
  &:hover {
    cursor: pointer;
  }
}
.backBlack {
  background: #000;
  height: 60px;
  margin-bottom: 20px;
  .v-text-field input {
    color: #fff !important;
  }
}
.keyInput {
  .v-input__slot {
    padding: 0 !important;
  }
  fieldset {
    padding: 0 !important;
    border-width: 0 !important;
  }
  width: 110px;
}
.btnAct {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  a {
    text-decoration: none;
  }
  .v-btn {
    background: #1ea333 !important;
    color: #fff !important;
  }
}

.croppa-container {
  position: relative;
  canvas {
    top: 3px;
    position: relative;
  }
  svg {
    position: absolute;
    top: -5px !important;
    right: -10px !important;
  }
}
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.viewMore {
  .v-icon {
    &:last-of-type {
      margin-left: -18px;
    }
  }
}

.footerBackGround {
  .v-input__control {
    fieldset {
      background: #fff !important;
    }
  }
}

.fixedVerify {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.vue-pincode-input {
  max-width: 70px !important;
  height: 60px !important;
  // any styles you want for each cell
}

a {
  text-decoration: none !important;
}

.page {
  img {
    max-width: 100%;
    object-fit: contain;
  }
}

.siderBox {
  box-shadow: 0 0 3px #c0bebe;
  padding: 15px;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .v-icon {
      font-size: 26px;
    }
    strong {
      font-size: 18px;
    }
    a {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 16px;
    }
  }
}
.vueditor {
  min-height: 300px !important;
}
.tablebox {
  td,
  tr {
    font-size: 16px !important;
  }
}
.bodySection {
  .v-btn {
    color: #fff !important;
  }
}
.slick-slide img {
  display: block;
  width: 100%;
  border: 1px solid #efefef;
}
.primaryBtn {
  min-height: 50px;
  // width: 100%;
  background: #0878d4 !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}
.danger {
  position: absolute;
  top: 15px;
  left: 15px;
  .v-chip {
    background: #f44336 !important;
    border: 1px solid #f44336;
    box-shadow: none !important;
    color: #fff !important;
    font-weight: 600;
  }
}
</style>