import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";
// import router from "../../router";

const state = () => ({
    analytic: undefined,
});

const getters = {
    allAnalytic: (state) => state.analytic,
};

const actions = {
    async getAnalytic({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}analytic/report`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setAnalytic", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
}

const mutations = {
    setAnalytic: (state, data) => {
        return state.analytic = data;
    }
};

export default { state, getters, actions, mutations };
