<template>
  <section class="dashboarBoard">
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col md="2" class="px-0 py0 menuColor">
            <Header />
          </v-col>
          <v-col md="10">
            <div class="headerSection">
              <router-link to="/business"
                ><img src="@/assets/logo.png" alt="" />
              </router-link>
            </div>
            <router-view
              :key="$route.fullPath"
              class="bodySection"
            ></router-view>
          </v-col>
        </v-row>
      </v-container>

      <Footer v-if="false" />
    </v-main>
  </section>
</template>

<script>
import Header from "@/components/business/Header";
import Footer from "@/components/business/Footer";
export default {
  name: "Business",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.bodySection {
  padding-bottom: 30px;
  min-height: 100vh;
  // max-width: 1200px;
  // left: 100px;
  position: relative;
}
.menuColor{
    background: #0878d4;
}
.headerSection{
  img{
    width: 200px;
  }
}
</style>