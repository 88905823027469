import Axios from "axios";
import router, { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    carts: undefined,
    checkouts: undefined,
});


const getters = {
    allCarts: (state) => state.carts,
    allCheckouts: (state) => state.checkouts,
};

const actions = {
    async postCart({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}cart/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setCarts", response.data);
            }
        } catch (error) {
            if(error.response != undefined){
                commit("setCarts", error.response.data);
            }else{
                
                commit("setCarts", {results:[],message:"Please login first"});
                setTimeout(()=>{
                    router.push("/login")
                },1000)
         
            }
           
        }
    },
    async getCarts({ commit }) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}cart/cartList`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setCarts", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteCart({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}cart/singleCart/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setCarts", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async postCheckout({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}cart/processCheckout`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setCarts", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getCheckouts({ commit }) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}cart/checkoutList`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setCheckouts", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setCarts: (state, data) => {
        return state.carts = data;
    },
    setCheckouts: (state, data) => {
        // state.carts.details = data.details;
        return state.checkouts = data;
    },
};

export default { state, getters, actions, mutations };
