import Axios from "axios";
import  { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    contact: undefined,
    contacts: [],
});


const getters = {
    allContact: (state) => state.contact,
    allContacts: (state) => state.contacts,
};

const actions = {
    async postContact({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}contact/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setContact", response);
            }
        } catch (error) {
            if(error.response != undefined){
                commit("setContact", error.response.data);
            }
        }
    },
    async getContacts({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}contact/contactList`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setContacts", response.data);
            }
        } catch (error) {
           console.log(error);
        }
    }
    
   
}

const mutations = {
    setContact: (state, data) => {
        return state.contact = data;
    },
    setContacts: (state, data) => {
        return state.contacts = data;
    },
};

export default { state, getters, actions, mutations };
