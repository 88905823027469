import Business from "../views/Business";
const businessmenu = [
  {
    path: "/business",
    component: Business,
    children: [
      {
        path: "/business/chargingStation",
        name: "ChangringStation",
        component: () =>
          import(/* webpackChunkName: "ChangringStation" */ "../views/business/page/ChargingStation"),
      },
      {
        path: "/business/addChargingStation",
        name: "AddChargingStation",
        component: () =>
          import(/* webpackChunkName: "AddChargingStation" */ "../views/business/form/AddChargingStation"),
      },
      {
        path: "/business/addChargingStation/:id",
        name: "UpdateChangringStation",
        component: () =>
          import(/* webpackChunkName: "UpdateChangringStation" */ "../views/business/form/AddChargingStation"),
      },
      {
        path: "/business/serviceCenter",
        name: "ServiceCenter",
        component: () =>
          import(/* webpackChunkName: "ServiceCenter" */ "../views/business/page/ServiceCenter"),
      },
      {
        path: "/business/addServiceCenter",
        name: "AddServiceCenter",
        component: () =>
          import(/* webpackChunkName: "AddServiceCenter" */ "../views/business/form/AddServiceCenter"),
      },
      {
        path: "/business/contacts",
        name: "Contact",
        component: () =>
          import(/* webpackChunkName: "Contact" */ "../views/business/page/Contact"),
      },
      {
        path: "/business/bookings",
        name: "Booking",
        component: () =>
          import(/* webpackChunkName: "Booking" */ "../views/business/page/Booking"),
      },
      {
        path: "/business/singleBooking/:id",
        name: "SingleBooking",
        component: () =>
          import(/* webpackChunkName: "SingleBooking" */ "../views/business/page/SingleBooking"),
      },
      {
        path: "/business/addServiceCenter/:id",
        name: "UpdateServiceCenter",
        component: () =>
          import(/* webpackChunkName: "UpdateServiceCenter" */ "../views/business/form/AddServiceCenter"),
      },
      {
        path: "/business/orders",
        name: "Order",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/business/page/Order"),
      },
      {
        path: "/business/singleOrder/:id",
        name: "SingleOrder",
        component: () =>
          import(/* webpackChunkName: "SingleOrder" */ "../views/business/page/SingleOrder"),
      },
      {
        path: "/business/addAccount",
        name: "AddAccount",
        component: () =>
          import(/* webpackChunkName: "AddAccount" */ "../views/business/form/AddAccount"),
      },
      {
        path: "/business/accountList",
        name: "Account",
        component: () =>
          import(/* webpackChunkName: "Account" */ "../views/business/page/Account"),
      },
      {
        path: "/business/addPage/:id",
        name: "UpdatePage",
        component: () =>
          import(/* webpackChunkName: "UpdatePage" */ "../views/business/form/AddPage"),
      },
      {
        path: "/business/addPage",
        name: "AddPage",
        component: () =>
          import(/* webpackChunkName: "AddPage" */ "../views/business/form/AddPage"),
      },
      {
        path: "/business/pages",
        name: "Page",
        component: () =>
          import(/* webpackChunkName: "Page" */ "../views/business/page/Page"),
      },
      {
        path: "/business/addBatteryType/:id",
        name: "UpdateBatteryType",
        component: () =>
          import(/* webpackChunkName: "UpdateBatteryType" */ "../views/business/form/AddBatteryType"),
      },
      {
        path: "/business/addBatteryType",
        name: "AddBatteryType",
        component: () =>
          import(/* webpackChunkName: "AddBatteryType" */ "../views/business/form/AddBatteryType"),
      },
      {
        path: "/business/batterytypes",
        name: "BatteryType",
        component: () =>
          import(/* webpackChunkName: "BatteryType" */ "../views/business/page/BatteryType"),
      },
      {
        path: "/business/addBanner/:id",
        name: "UpdateBanner",
        component: () =>
          import(/* webpackChunkName: "UpdateBanner" */ "../views/business/form/AddBanner"),
      },
      {
        path: "/business/addBanner",
        name: "AddBanner",
        component: () =>
          import(/* webpackChunkName: "AddBanner" */ "../views/business/form/AddBanner"),
      },
      {
        path: "/business/banners",
        name: "Banner",
        component: () =>
          import(/* webpackChunkName: "Banner" */ "../views/business/page/Banner"),
      },
      {
        path: "/business/addYear/:id",
        name: "UpdateYear",
        component: () =>
          import(/* webpackChunkName: "UpdateYear" */ "../views/business/form/AddYear"),
      },
      {
        path: "/business/addYear",
        name: "AddYear",
        component: () =>
          import(/* webpackChunkName: "AddYear" */ "../views/business/form/AddYear"),
      },
      {
        path: "/business/years",
        name: "Year",
        component: () =>
          import(/* webpackChunkName: "Year" */ "../views/business/page/Year"),
      },
      {
        path: "/business/menus",
        name: "Menus",
        component: () =>
          import(/* webpackChunkName: "Menus" */ "../views/business/page/Menu"),
      },
      {
        path: "/business/addMenu/:id",
        name: "UpdatedaMenu",
        component: () =>
          import(/* webpackChunkName: "UpdatedaMenu" */ "../views/business/form/AddMenu"),
      },
      {
        path: "/business/addMenu",
        name: "AddMenu",
        component: () =>
          import(/* webpackChunkName: "AddMenu" */ "../views/business/form/AddMenu"),
      },
      {
        path: "/business/menus",
        name: "Menus",
        component: () =>
          import(/* webpackChunkName: "Menus" */ "../views/business/page/Menu"),
      },
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/business/page/Home"),
      },
      {
        path: "/business/bodytypes",
        name: "BodyType",
        component: () =>
          import(/* webpackChunkName: "BodyType" */ "../views/business/page/BodyType"),
      },
      {
        path: "/business/addbodytype",
        name: "AddBodyType",
        component: () =>
          import(/* webpackChunkName: "AddBodyType" */ "../views/business/form/AddBodyType"),
      },
      {
        path: "/business/addbodytype/:id",
        name: "UpdateBodyType",
        component: () =>
          import(/* webpackChunkName: "UpdateBodyType" */ "../views/business/form/AddBodyType"),
      },
      {
        path: "/business/colors",
        name: "Color",
        component: () =>
          import(/* webpackChunkName: "Color" */ "../views/business/page/Color"),
      },
      {
        path: "/business/addColor",
        name: "AddColor",
        component: () =>
          import(/* webpackChunkName: "AddColor" */ "../views/business/form/AddColor"),
      },
      {
        path: "/business/addColor/:id",
        name: "UpdateColor",
        component: () =>
          import(/* webpackChunkName: "UpdateColor" */ "../views/business/form/AddColor"),
      },
      {
        path: "/business/addblog/:id",
        name: "UpdateBlog",
        component: () =>
          import(/* webpackChunkName: "UpdateBlog" */ "../views/business/form/AddBlog"),
      },
      {
        path: "/business/addblog",
        name: "AddBlog",
        component: () =>
          import(/* webpackChunkName: "AddBlog" */ "../views/business/form/AddBlog"),
      },
      {
        path: "/business/blogs",
        name: "Blog",
        component: () =>
          import(/* webpackChunkName: "Blog" */ "../views/business/page/Blog"),
      },
      {
        path: "/business/brands",
        name: "Brand",
        component: () =>
          import(/* webpackChunkName: "Brand" */ "../views/business/page/Brand"),
      },
      {
        path: "/business/addBrand",
        name: "AddBrand",
        component: () =>
          import(/* webpackChunkName: "AddBrand" */ "../views/business/form/AddBrand"),
      },
      {
        path: "/business/addBrand/:id",
        name: "UpdateBrand",
        component: () =>
          import(/* webpackChunkName: "UpdateBrand" */ "../views/business/form/AddBrand"),
      },
      {
        path: "/business/insurancecompany",
        name: "InsuranceCompany",
        component: () =>
          import(/* webpackChunkName: "InsuranceCompany" */ "../views/business/page/InsuranceCompany"),
      },
      {
        path: "/business/addinsurancecompany",
        name: "AddInsuranceCompany",
        component: () =>
          import(/* webpackChunkName: "AddInsuranceCompany" */ "../views/business/form/AddInsuranceCompany"),
      },
      {
        path: "/business/products",
        name: "Product",
        component: () =>
          import(/* webpackChunkName: "Product" */ "../views/business/page/Product"),
      },
      {
        path: "/business/addproduct",
        name: "AddProduct",
        component: () =>
          import(/* webpackChunkName: "AddProduct" */ "../views/business/form/AddProduct"),
      },
      {
        path: "/business/addproduct/:id",
        name: "UpdateProduct",
        component: () =>
          import(/* webpackChunkName: "UpdateProduct" */ "../views/business/form/AddProduct"),
      },
      {
        path: "/business/categories",
        name: "Categories",
        component: () =>
          import(/* webpackChunkName: "Categories" */ "../views/business/page/Category"),
      },
      {
        path: "/business/category/add",
        name: "AddCategory",
        component: () =>
          import(/* webpackChunkName: "AddCategory" */ "../views/business/form/category/AddCategory"),
      },
      {
        path: "/business/category/add/:id",
        name: "UpdateCategory",
        component: () =>
          import(/* webpackChunkName: "AddCategory" */ "../views/business/form/category/AddCategory"),
      },
      {
        path: "/business/subcategory/add",
        name: "AddSubCategory",
        component: () =>
          import(/* webpackChunkName: "AddSubCategory" */ "../views/business/form/category/AddSubCategory"),
      },
      {
        path: "/business/subcategory/add/:id",
        name: "UpdateSubCategory",
        component: () =>
          import(/* webpackChunkName: "UpdateSubCategory" */ "../views/business/form/category/AddSubCategory"),
      },
      {
        path: "/business/servicecenter",
        name: "ServiceCenter",
        component: () =>
          import(/* webpackChunkName: "ServiceCenter" */ "../views/business/page/ServiceCenter"),
      },
      {
        path: "/business/addservicecenter",
        name: "AddServiceCenter",
        component: () =>
          import(/* webpackChunkName: "AddServiceCenter" */ "../views/business/form/AddServiceCenter"),
      },
      {
        path: "/business/taxcalculator",
        name: "TaxCalculator",
        component: () =>
          import(/* webpackChunkName: "TaxCalculator" */ "../views/user/form/TaxCalculator"),
      },
      {
        path: "/business/logs",
        name: "Log",
        component: () =>
          import(/* webpackChunkName: "Log" */ "../views/business/page/Log"),
      },
    ],
  },
];

export default businessmenu;
