import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";
// import router from "../../router";

const state = () => ({
  register: undefined,
  verifyPopup: false,
  login: undefined,
  userInfo: undefined,
  userDetail: undefined,
  forgetPassword: undefined,
  forgetPasswordVerify: undefined,
  passwordUpdate: undefined,
  users: [],
});

const getters = {
  allRegister: (state) => state.register,
  allVerifyPopup: (state) => state.verifyPopup,
  allLogin: (state) => state.login,
  allForgetPassword: (state) => state.forgetPassword,
  allForgetPasswordVerify: (state) => state.forgetPasswordVerify,
  allUserDetail: (state) => state.userDetail,
  allPassword: (state) => state.passwordUpdate,
  allUsers: (state) => state.users,
};

const actions = {
  async postRegister({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}user/register`, data);
      if (response.status == 201) {
        sessionStorage.setItem("email", data.email);
        commit("setRegister", response);
      }
    } catch (error) {
      console.log(error);
      commit("setRegister", error.response);
    }
  },
  async postVerify({ commit }, data) {
    try {
      if (sessionStorage.getItem("email")) {
        data.email = sessionStorage.email;
      }
      const response = await Axios.put(`${baseUrl}user/verify`, data);
      if (response.status == 200) {
        commit("setRegister", response);
        sessionStorage.removeItem("email");
      }
    } catch (error) {
      console.log(error);
    }
  },
  async postLogin({ commit }, data) {
    try {
      sessionStorage.setItem("email", data.email);
      const response = await Axios.post(`${baseUrl}user/login`, data);
      if (response.status == 200) {
        localStorage.setItem("token", JSON.stringify(response.data.results));
        commit("setLogin", response);
        sessionStorage.removeItem("email");
      }
    } catch (error) {
      commit("setLogin", error.response);
    }
  },
  async postSocialLogin({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}user/socialLogin`, data);
      if (response.status == 200) {
        localStorage.setItem("token", JSON.stringify(response.data.results));
        commit("setLogin", response);
      }
    } catch (error) {
      commit("setLogin", error.response);
    }
  },
  async postForgetPassword({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}user/forgetPassword`, data);
      if (response.status == 200) {
        sessionStorage.setItem("email", data.email);
        commit("setForgetPassword", response);
      }
    } catch (error) {
      commit("setForgetPassword", error.response);
    }
  },
  async postForgetPasswordVerify({ commit }, data) {
    try {
      let dat = {};
      dat = data;
      console.log;
      dat.email = sessionStorage.getItem("email");
      const response = await Axios.put(
        `${baseUrl}user/forgetPasswordVerify`,
        dat
      );
      if (response.status == 200) {
        sessionStorage.removeItem("email");
        commit("setForgetPasswordVerify", response);
      }
    } catch (error) {
      commit("setForgetPasswordVerify", error.response);
    }
  },
  async getUserDetail({ commit }) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.get(
        `${baseUrl}user/userprofile`,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setUserDetail", response.data);
      }
    } catch (error) {
      commit("setUserDetail", error.response);
    }
  },
  async putUserDetail({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.put(
        `${baseUrl}user/updateProfile`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setUserDetail", response.data);
      }
    } catch (error) {
      commit("setUserDetail", error.response);
    }
  },
  async putPassword({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.put(
        `${baseUrl}user/changePassword`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setNewPassword", response);
      }
    } catch (error) {
      commit("setNewPassword", error.response);
    }
  },
  async getUsers({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.post(
        `${baseUrl}user/users`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setUsers", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async postUser({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.post(
        `${baseUrl}user/registerByAdmin`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 201) {
        commit("setUsers", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async putUserStatus({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.put(
        `${baseUrl}user/deactivateUser`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setUsers", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getOTP({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}user/resendotp`, data);
      if (response.status == 200) {
        commit("setUser", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  getLogin({ commit }) {
    if (localStorage.getItem("token"));
    commit("setLogin", JSON.parse(localStorage.getItem("token")));
  },
  logout({ commit }) {
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => location.reload(), 1500);
    commit("setLogin", undefined);
  },
};

const mutations = {
  setRegister: (state, data) => {
    if (data != undefined && data.status == 201) {
      state.verifyPopup = true;
    } else {
      state.verifyPopup = false;
    }
    return (state.register = data);
  },
  setLogin: (state, data) => {
    if (data != undefined && data.status == 443) {
      state.verifyPopup = true;
    }

    return (state.login = data);
  },
  setForgetPassword: (state, data) => {
    return (state.forgetPassword = data);
  },
  setForgetPasswordVerify: (state, data) => {
    return (state.forgetPasswordVerify = data);
  },
  setUserDetail: (state, data) => {
    return (state.userDetail = data);
  },
  setNewPassword: (state, data) => {
    return (state.passwordUpdate = data);
  },
  setUsers: (state, data) => {
    return (state.users = data);
  },
};

export default { state, getters, actions, mutations };
