import Axios from "axios";
import router, { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    booking: undefined,
    bookings: [],
});


const getters = {
    allBooking: (state) => state.booking,
    allBookings: (state) => state.bookings,
};

const actions = {
    async postBooking({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}booking/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setBooking", response.data);
            }
        } catch (error) {
            if(error.response != undefined){
                commit("setBooking", error.response.data);
            }else{
                commit("setBooking", {results:[],message:"Please login first"});
                setTimeout(()=>{
                    router.push("/login")
                },1000)
         
            }
           
        }
    },
    async getBookings({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}booking/bookingList`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBookings", response.data);
            }
        } catch (error) {
           console.log(error);
        }
    },
    async getBooking({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}booking/singleBooking/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBooking", response.data);
            }
        } catch (error) {
           console.log(error);
        }
    },
    
   
}

const mutations = {
    setBooking: (state, data) => {
        return state.booking = data;
    },
    setBookings: (state, data) => {
        return state.bookings = data;
    },
};

export default { state, getters, actions, mutations };
