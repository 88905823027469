import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    bodyType:undefined,
    bodyTypes:undefined,
});

const getters = {
    allBodyType:(state)=>state.bodyType,
    allBodyTypes:(state)=>state.bodyTypes,
};

const actions = {
    async postBodyType({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}bodyType/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setBodyType", response);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBodyTypeList({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}bodyType/bodyTypeList`, data,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBodyTypes", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleBodyType({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}bodyType/singleBodyType/${data}`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBodyType", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBodyTypeFrontend({commit},data){
        try {
            const response = await Axios.post(`${baseUrl}bodyType/bodyTypesFrontend`, data);
            if (response.status == 200) {
                commit("setBodyTypes", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteBodyType({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}bodyType/singleBodyType/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBodyTypes", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setBodyType: (state, data) => {
        return state.bodyType = data;
    },
    setBodyTypes: (state, data) => {
        return state.bodyTypes = data;
    },
};

export default { state, getters, actions, mutations };
