import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    batteryType:undefined,
    batteryTypes:undefined,
});

const getters = {
    allBatteryType:(state)=>state.batteryType,
    allBatteryTypes:(state)=>state.batteryTypes,
};

const actions = {
    async postBatteryType({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}batteryType/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setBatteryType", response);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBatteryTypeList({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}batteryType/batteryTypeList`, data,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBatteryTypes", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleBatteryType({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}batteryType/singleBatteryType/${data}`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBatteryType", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBatteryTypeFrontend({commit},data){
        try {
            const response = await Axios.post(`${baseUrl}batteryType/batteryTypesFrontend`, data);
            if (response.status == 200) {
                commit("setBatteryTypes", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteBattery({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}batteryType/singleBatteryType/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBatteryTypes", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setBatteryType: (state, data) => {
        return state.batteryType = data;
    },
    setBatteryTypes: (state, data) => {
        return state.batteryTypes = data;
    },
};

export default { state, getters, actions, mutations };
