<template>
  <section v-if="status">
    <div class="copyright">
      {{ new Date().getFullYear() }}
      <v-icon class="mx-1"> mdi-copyright </v-icon>
      <strong>EV Drive</strong>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
 
};
</script>

<style lang="scss" scoped>
.copyright {
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px #efefef;
}
</style>