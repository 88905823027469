import Vue from 'vue'
import VueRouter from 'vue-router'
import frontendmenu from './frontend';
import businessmenu from './business';

Vue.use(VueRouter)

const routes = [...frontendmenu,...businessmenu];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export const baseUrl = "https://api.evsteer.com/api/v1/";
// export const baseUrl = "http://localhost:8080/api/v1/";

export let tokenHeader;
if (localStorage.getItem("token")) {
  tokenHeader = {
    headers: {
      "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
    }
  };
}




export default router
