<template>
  <section class="footerBackGround" v-if="status">
    <v-container class="container-custom">
      <div class="pt-10 pb-2 borderBtm">
        <v-row>
          <v-col md="6" cols="12">
            <h2>Know it all first!</h2>
            <p>Never Miss Anything From us By Signing Up To Our Newsletter.</p>
          </v-col>
          <v-col md="6" cols="12">
            <div class="d-flex">
              <v-text-field
                placeholder="Enter your Email"
                outlined
              ></v-text-field>
              <v-btn class="mt-2 ml-3 primaryBtn" width="200" large>
                Subscribe <v-icon> mdi-bell-ring-outline </v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="footerMenu d-flex justify-space-between borderBtm">
        <div>
          <h3>Overview</h3>
          <ul v-if="!menuLoading">
            <li v-for="(data, index) in menus" :key="index + 'footer'">
              <router-link :to="data.link">{{ data.menuName }}</router-link>
            </li>
          </ul>
          <ul v-if="menuLoading">
            <li v-for="n in 5" :key="n">
              <v-skeleton-loader
                class="mx-auto width100"
                height="20"
                type="image"
              ></v-skeleton-loader>
            </li>
          </ul>
        </div>
        <div>
          <h3>Services</h3>
          <ul>
            <li>
              <router-link to="/emicalculator">EMI Calculator</router-link>
            </li>
            <!-- <li><router-link to="/">Insurance</router-link></li> -->
            <!-- <li><router-link to="/">Charging Station</router-link></li> -->
            <li><router-link to="/compare">Compare Car</router-link></li>
            <!-- <li><router-link to="/">Service/Repair</router-link></li> -->
          </ul>
        </div>
        <div>
          <h3>Popular Car Brands</h3>
          <ul v-if="menuLoading">
            <li v-for="n in 5" :key="n">
              <v-skeleton-loader
                class="mx-auto width100"
                height="20"
                type="image"
              ></v-skeleton-loader>
            </li>
          </ul>
          <ul v-if="!menuLoading">
            <li v-for="(data, index) in brandFour" :key="index + 'four'">
              <router-link :to="`/search?brand=${data.brandName}`">{{
                data.brandName
              }}</router-link>
            </li>
          </ul>
        </div>
        <div>
          <h3>Popular Bike Brands</h3>
          <ul v-if="menuLoading">
            <li v-for="n in 5" :key="n">
              <v-skeleton-loader
                class="mx-auto width100"
                height="20"
                type="image"
              ></v-skeleton-loader>
            </li>
          </ul>
          <ul v-if="!menuLoading">
            <li v-for="(data, index) in brandTwo" :key="index + 'four'">
              <router-link :to="`/search?brand=${data.brandName}`">{{
                data.brandName
              }}</router-link>
            </li>
          </ul>
        </div>
        <div>
          <h3>Contact Us</h3>
          <ul>
            <li><a href="tel:+9779851320368">+977 9851320368</a></li>
            <li>
              <a href="mailto:marketing@evsteer.com">marketing@evsteer.com</a>
            </li>
            <li>Kathmandu, Nepal</li>
          </ul>
          <h3>Social</h3>
          <ul class="d-flex socialIcon">
            <li>
              <a
                href="https://www.facebook.com/electricsteer"
                target="_blank"
                rel="noopener noreferrer"
                ><v-icon> mdi-facebook </v-icon></a
              >
            </li>
            <li>
              <a
                href="https://wa.me/+9779851320368"
                target="_blank"
                rel="noopener noreferrer"
                ><v-icon> mdi-whatsapp </v-icon></a
              >
            </li>
            <li>
              <a
                href="https://www.instagram.com/evdriveinnepal22/"
                target="_blank"
                rel="noopener noreferrer"
                ><v-icon> mdi-instagram </v-icon></a
              >
            </li>
            <li>
              <a
                href="https://twitter.com/evdrivein"
                target="_blank"
                rel="noopener noreferrer"
                ><v-icon> mdi-twitter </v-icon></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="copyright">
        <v-icon> mdi-copyright </v-icon> {{ new Date().getFullYear() }} – EV
        Steer. All Rights Reserved.
      </div>
    </v-container>
    <div class="fixCompare" v-if="$route.path !== '/compare' && allCompareProducts">
      <header class="text-center">
        Click to open compare
        <v-icon @click="toggleCompare" v-if="!compareFixedBox">mdi-chevron-up</v-icon>
        <v-icon @click="toggleCompare" v-if="compareFixedBox">mdi-chevron-down</v-icon>
      </header>
      <v-container class="container-custom" v-if="compareFixedBox">
        <v-row>
          <v-col md="12">
            <h1>Compare Product</h1>
          </v-col>
          <v-col
            md="3"
            cols="12"
            v-for="(data, index) in allCompareProducts"
            :key="index + 'car'"
          >
            <div class="compareBox">
              <img
                v-if="data.medias"
                :src="data.medias[0].mediaLink"
                alt=""
                class="compareImage"
              />
              <h2>{{ data.productName }}</h2>
              <h4>{{ data.category }}</h4>

              <v-btn
                class="primaryBtn mt-4"
                @click="deleteCompareProduct(data._id)"
                >Remove</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Footer",
  data: () => ({
    menuLoading: true,
    menus: [],
    brandFour: [],
    brandTwo: [],
    status: true,
    compareFixedBox: false,
  }),

  methods: {
    ...mapActions([
      "getFrontendMenus",
      "getBrandFrontendFooter",
      "getCompareProducts",
      "deleteCompareProduct",
    ]),
    toggleCompare() {
      this.compareFixedBox = !this.compareFixedBox;
    },
  },
  computed: {
    ...mapGetters(["allMenusFooter", "allBrandsFooter", "allCompareProducts"]),
  },
  async created() {
    if (this.$route.query.header != undefined) {
      this.status = false;
    } else {
      this.status = true;
    }
    this.getCompareProducts();
    if (this.allMenusFooter == undefined) {
      await this.getFrontendMenus("footer");
    }
    this.menus = this.allMenusFooter.results;
    await this.getBrandFrontendFooter({
      limit: 5,
      page: 1,
      category: "2 Wheeler",
    });
    this.brandTwo = this.allBrandsFooter.results;

    await this.getBrandFrontendFooter({
      limit: 5,
      page: 1,
      category: "4 Wheeler",
    });
    this.brandFour = this.allBrandsFooter.results;

    this.menuLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.footerBackGround {
  background: #f4f4f4;
  padding-left: 15px;
  @media only screen and (max-width: 991px) {
    flex-flow: column;
    // margin-right: -10px;
  }
}
.footerMenu {
  padding: 40px 0;
  @media only screen and (max-width: 991px) {
    flex-flow: column;
  }
  h3 {
    margin-bottom: 15px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      margin-bottom: 10px;
      a {
        font-size: 14px;
        text-decoration: none;
        color: #444444;
      }
    }
  }
  .socialIcon {
    li {
      margin-right: 10px;
    }
  }
}
.borderBtm {
  border-bottom: 1px solid #efefef;
}
.primaryBtn {
  min-height: 55px;
  margin-top: -0px !important;
  background: #0878d4 !important;
  .v-icon {
    margin-left: 10px;
    font-size: 18px;
  }
}
.copyright {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 14px;
  .v-icon {
    font-size: 18px;
    margin-right: 10px;
  }
}
.v-btn {
  color: #fff;
}
.compareBox {
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 4px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 200px;
  .compareImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  div {
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-flow: column;
    width: 100%;
    justify-content: center;
  }
  h2 {
    font-size: 14px;
  }
  h4 {
    margin-bottom: 20px;
    font-size: 12px;
  }
  .primaryBtn {
    min-height: 20px;
    width: 100px;
    margin-bottom: 10px;
  }
}
.fixCompare {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgb(241, 241, 241);
  padding: 10px 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 99999;
}
</style>