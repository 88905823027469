import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    year: undefined,
    years: undefined,
});

const getters = {
    allYear: (state) => state.year,
    allYears: (state) => state.years,
};

const actions = {
    async postYear({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}year/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setYear", response);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getYearList({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}year/yearList`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setYears", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleYear({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}year/singleYear/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setYear", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getYearFrontend({ commit }, data) {
        try {
            const response = await Axios.post(`${baseUrl}year/yearsFrontend`, data);
            if (response.status == 200) {
                commit("setYears", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteYear({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}year/singleYear/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setYears", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setYear: (state, data) => {
        return state.year = data;
    },
    setYears: (state, data) => {
        return state.years = data;
    },
};

export default { state, getters, actions, mutations };
