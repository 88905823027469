<template>
  <section>
    <v-progress-circular
      indeterminate
      color="primary"
      size="120"
    ></v-progress-circular>
    <h4 class="mt-5">EV Drive</h4>
  </section>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style lang="scss" scoped>
section{
  text-align: center;
}
</style>