import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";
// import router from "../../router";

const state = () => ({
    blog: undefined,
    blogs: undefined,
});

const getters = {
    allBlog: (state) => state.blog,
    allBlogs: (state) => state.blogs,
};

const actions = {
    async postBlog({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}blog/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setBlog", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBlogs({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}blog/blogList`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBlogs", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleBlog({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}blog/singleBlog/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBlog", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getFrontendSingleBlog({ commit }, data) {
        try {
            const response = await Axios.get(`${baseUrl}blog/singleFrontBlog/${data}`);
            if (response.status == 200) {
                commit("setBlog", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getFrontendBlogs({ commit }, data) {
        try {
            const response = await Axios.post(`${baseUrl}blog/blogsFrontend`, data);
            if (response.status == 200) {
                commit("setBlogs", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBlogSelects({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}blog/blogsSelects`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBlogs", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteBlog({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}blog/singleBlog/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBlogs", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },

}

const mutations = {
    setBlog: (state, data) => {
        return state.blog = data;
    },
    setBlogs: (state, data) => {
        return state.blogs = data;
    },
};

export default { state, getters, actions, mutations };
