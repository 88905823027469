import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";
// import router from "../../router";

const state = () => ({
    reasons: undefined,
});

const getters = {
    allReasons: (state) => state.reasons,
};

const actions = {
    async getReasons({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}reason/reasonList/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setReasons", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
}

const mutations = {
    setReasons: (state, data) => {
        return state.reasons = data;
    }
};

export default { state, getters, actions, mutations };
