<template>
  <section>
    <img src="@/assets/afterlogo.png" alt="" />
  </section>
</template>

<script>
export default {
  name: "SpinningWheel",
};
</script>

<style lang="css" scoped>
img{
    width: 50px;
    object-fit: contain;
}
.tire {
  animation: rotation 1800ms infinite linear;
  transform-origin: 50% 50%;
}
.rim {
  animation: rotation 1200ms infinite linear;
  transform-origin: 50% 50%;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>