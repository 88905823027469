import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";
import router from "../../router";

const state = () => ({
  categories: [],
  category: [],
  subcategories: [],
  singleCategory: null,
  singleSubCategory: null,
  frontendSubCategory: null,
  categoryList: [],
  subcategoryList: [],
  thirdcategoryList: [],
});

const getters = {
  allCategory: (state) => state.categories,
  allSingleCategory: (state) => state.category,
  allSubcategory: (state) => state.singleSubCategory,
  allSubcategoriesDash: (state) => state.subcategories,
  allCategoryList: (state) => state.categoryList,
  allSubCategoryList: (state) => state.subcategoryList,
  allThirdCategoryList: (state) => state.thirdcategoryList,
};

const actions = {
  async fetchCategory({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "category/categoryList",
        data
      );
      commit("setCategory", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchCategoryById({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "category/singleCategoryById/"+data,
        data
      );
      commit("setSingleCategory", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchCategoryFrontend({ commit }) {
    try {
      let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
      const response = await Axios.get(baseUrl + "category/categorySelects", tokenHeader != undefined ? tokenHeader : tkHeader);
      commit("setCategory", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async postSubcategory({ commit }, data) {
    try {
      let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
      const response = await Axios.post(baseUrl + "subcategory/create", data, tokenHeader != undefined ? tokenHeader : tkHeader);
      router.push("/business/categories");
      commit("setSubcategory", response.data.results);
    } catch (error) {
      console.log(error);
    }

  },
  async postCategory({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
          tkHeader = {
              headers: {
                  "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
              }
          };
      }
      const response = await Axios.post(baseUrl + "category/create", data,tokenHeader != undefined ? tokenHeader : tkHeader);
      router.push("/business/categories");

      commit("setCategory", response.data.results);
    } catch (error) {
      console.log(error);
    }

  },
  

  async getCategoryList({ commit }) {
    try {
      let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
      const response = await Axios.get(baseUrl + "category/categories", tokenHeader != undefined ? tokenHeader : tkHeader);
      commit("setCategoryList", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getCategoryHomeList({ commit }) {
    try {
    
      const response = await Axios.get(baseUrl + "category/homeCategories");
      commit("setCategoryList", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSubCategoryList({ commit }, data) {
    try {
      let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
      const response = await Axios.post(baseUrl + "subcategory/subCategories", data, tokenHeader != undefined ? tokenHeader : tkHeader);
      commit("setSubCategoryList", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSubCategoryBySlug({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + `subcategory/singleSubCategory/${data}`
      );
      commit("setSubcategory", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },

};

const mutations = {
  setSingleCategory: (state, menu) => (state.category = menu),
  setCategory: (state, menu) => (state.categories = menu),
  setSubcategory: (state, menu) => (state.singleSubCategory = menu),
  setSubcategories: (state, menu) => (state.subcategories = menu),
  setFrontendSubCategory: (state, data) => (state.frontendSubCategory = data),
  setCategoryList: (state, data) => (state.categoryList = data),
  setSubCategoryList: (state, data) => (state.subcategoryList = data),
  setThirdCategoryList: (state, data) => (state.thirdcategoryList = data),
};

export default { state, getters, actions, mutations };
