import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import category from "./modules/category";
import product from "./modules/product";
import brand from "./modules/brand";
import color from "./modules/color";
import bodyType from "./modules/bodyType";
import cart from "./modules/cart";
import address from "./modules/address";
import order from "./modules/order";
import wishlist from "./modules/wishlist";
import blog from "./modules/blog";
import menu from "./modules/menu";
import advertisement from "./modules/advertisement";
import year from "./modules/year";
import batteryType from "./modules/batteryType";
import page from "./modules/page";
import chargingstation from "./modules/chargingstation";
import servicecenter from "./modules/servicecenter";
import analytic from "./modules/analytic";
import reason from "./modules/reason";
import review from "./modules/review";
import community from "./modules/community";
import booking from "./modules/booking";
import contact from "./modules/contact";
import log from "./modules/log";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    product,
    category,
    brand,
    color,
    bodyType,
    cart,
    address,
    order,
    wishlist,
    blog,
    menu,
    advertisement,
    year,
    batteryType,
    page,
    chargingstation,
    servicecenter,
    analytic,
    reason,
    review,
    community,
    booking,
    contact,
    log,
  },
});
