import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    wishlists: undefined,
});


const getters = {
    allWishlists: (state) => state.wishlists,
};

const actions = {
    async postWishlist({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}wishlist/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setWishlists", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getWishList({ commit }) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}wishlist/wishlistList`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setWishlists", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },

}

const mutations = {
    setWishlists: (state, data) => {
        return state.wishlists = data;
    },
};

export default { state, getters, actions, mutations };
