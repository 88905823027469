import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    rating: undefined,
    ratings: undefined,
});


const getters = {
    allRating: (state) => state.rating,
    allRatings: (state) => state.ratings,
};

const actions = {
    async postRating({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}reviewrating/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setRatings", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getRatingsList({ commit },data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}reviewrating/reviewRatingListing`, data,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setRatings", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getRatingsFrontend({ commit },data) {
        try {
            const response = await Axios.post(`${baseUrl}reviewrating/reviewRatingListingFront`, data);
            if (response.status == 200) {
                commit("setRatings", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleRating({ commit },data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}reviewrating/singleRating/${data}`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setRating", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteRating({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}reviewrating/deleteRating/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setRatings", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setRating: (state, data) => {
        return state.rating = data;
    },
    setRatings: (state, data) => {
        return state.ratings = data;
    },
};

export default { state, getters, actions, mutations };
