import Axios from "axios";
import store from "..";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    order: undefined,
    orders: undefined,
});


const getters = {
    allOrder: (state) => state.order,
    allOrders: (state) => state.orders,
};

const actions = {
    async postOrder({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}order/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setOrder", response.data);

                store.state.cart.carts.results = [];
                store.state.cart.checkouts.results = [];
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getOrders({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}order/orderList`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setOrders", response.data);

            }
        } catch (error) {
            console.log(error);
        }
    },
    async getOrder({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}order/singleOrder/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setOrder", response.data);

            }
        } catch (error) {
            console.log(error);
        }
    },
    async putOrder({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.put(`${baseUrl}order/changeOrderStatus`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setOrder", response.data);

            }
        } catch (error) {
            console.log(error);
        }
    }
}

const mutations = {
    setOrder: (state, data) => {
        return state.order = data;
    },
    setOrders: (state, data) => {
        return state.orders = data;
    },
};

export default { state, getters, actions, mutations };
