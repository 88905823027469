import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    brand:undefined,
    brands:undefined,
    brandsFooter:undefined,
});

const getters = {
    allBrand:(state)=>state.brand,
    allBrands:(state)=>state.brands,
    allBrandsFooter:(state)=>state.brandsFooter,
};

const actions = {
    async postBrand({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}brand/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setBrand", response);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBrandList({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}brand/brandList`, data,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBrands", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleBrand({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}brand/singleBrand/${data}`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBrand", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBrandFrontend({commit},data){
        try {
            const response = await Axios.post(`${baseUrl}brand/brandsFrontend`, data);
            if (response.status == 200) {
                commit("setBrands", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getBrandFrontendFooter({commit},data){
        try {
            const response = await Axios.post(`${baseUrl}brand/brandsFrontend`, data);
            if (response.status == 200) {
                commit("setBrandsFooter", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteBrand({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}brand/singleBrand/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setBrands", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setBrand: (state, data) => {
        return state.brand = data;
    },
    setBrands: (state, data) => {
        return state.brands = data;
    },
    setBrandsFooter: (state, data) => {
        return state.brandsFooter = data;
    },
};

export default { state, getters, actions, mutations };
