<template>
  <header>
    <div
      class="backBox"
      @click="menuToggle"
      :class="{ backBoxOp: menuStatus }"
    ></div>
    <div class="menuBox">
      <div class="userInitials">
        <span>
          {{ initials }}
        </span>
      </div>
      <div class="userWelcome">Hi! {{ user.fullname }}</div>
      <ul>
        <li>
          <router-link to="/business">
            <v-icon> mdi-home </v-icon> Dashboard</router-link
          >
        </li>

        <li>
          <router-link to="/business/products">
            <v-icon> mdi-package </v-icon> Products</router-link
          >
        </li>
        <li>
          <router-link to="/business/bookings?bookingType=Pending">
            <v-icon> mdi-book </v-icon> Bookings</router-link
          >
        </li>
        <li>
          <router-link to="/business/contacts">
            <v-icon> mdi-phone </v-icon> Contacts</router-link
          >
        </li>

        <li>
          <router-link to="/business/orders?orderType=Pending">
            <v-icon> mdi-package </v-icon> Orders</router-link
          >
        </li>
        <li
          class="menu-dropdown"
          v-if="
            user.role == 'admin' ||
            user.role == 'superadmin' ||
            user.role == 'editor'
          "
        >
          <span @click="menuToggle"
            ><v-icon> mdi-cog </v-icon> Settings
            <v-icon class="ml-3" v-if="menuDropdownStatus">
              mdi-chevron-up
            </v-icon>
            <v-icon class="ml-3" v-if="!menuDropdownStatus">
              mdi-chevron-down
            </v-icon>
          </span>
          <ul v-if="menuDropdownStatus">
            <li>
              <router-link
                to="/business/categories"
                v-if="user.role == 'admin' || user.role == 'superadmin'"
              >
                <v-icon> mdi-shape </v-icon>
                Categories</router-link
              >
            </li>

            <li>
              <router-link to="/business/brands">
                <v-icon> mdi-format-list-bulleted </v-icon>
                Brands</router-link
              >
            </li>
            <li>
              <router-link to="/business/bodytypes">
                <v-icon> mdi-format-list-bulleted </v-icon>
                Body Types</router-link
              >
            </li>
            <li>
              <router-link to="/business/batterytypes">
                <v-icon> mdi-format-list-bulleted </v-icon> Battery
                Types</router-link
              >
            </li>
            <li>
              <router-link to="/business/menus">
                <v-icon> mdi-format-list-bulleted </v-icon>
                Menu</router-link
              >
            </li>
            <li>
              <router-link to="/business/years">
                <v-icon> mdi-format-list-bulleted </v-icon>
                Year</router-link
              >
            </li>
            <li>
              <router-link
                to="/business/pages"
                v-if="user.role == 'admin' || user.role == 'superadmin'"
              >
                <v-icon> mdi-file-outline </v-icon> Page</router-link
              >
            </li>
            <li>
              <router-link
                to="/business/banners"
                v-if="user.role == 'admin' || user.role == 'superadmin'"
              >
                <v-icon> mdi-folder-multiple-image </v-icon>
                Advertisement</router-link
              >
            </li>
            <li>
              <router-link to="/business/blogs">
                <v-icon> mdi-post </v-icon> Blogs</router-link
              >
            </li>
            <li>
              <router-link to="/business/colors">
                <v-icon> mdi-post </v-icon> Colors</router-link
              >
            </li>
            <li>
              <router-link to="/business/chargingStation">
                <v-icon> mdi-post </v-icon> Charging Stations</router-link
              >
            </li>
            <li>
              <router-link to="/business/serviceCenter">
                <v-icon> mdi-post </v-icon> Service Center</router-link
              >
            </li>
            <li>
              <router-link
                to="/business/accountList"
                v-if="user.role == 'admin' || user.role == 'superadmin'"
              >
                <v-icon> mdi-account-group </v-icon> Accounts</router-link
              >
            </li>
            <li>
              <router-link
                to="/business/logs"
                v-if="user.role == 'admin' || user.role == 'superadmin'"
              >
                <v-icon> mdi-list-box-outline </v-icon> Logs</router-link
              >
            </li>
          </ul>
        </li>

        <!-- <li>
                <router-link to="/business/servicecenter">
                  <v-icon> mdi-account-multiple-plus </v-icon> Service
                  Centers</router-link
                >
              </li>
              <li>
                <router-link to="/business/insurancecompany">
                  <v-icon> mdi-finance </v-icon> Insurance
                  Companies</router-link
                >
              </li> -->
        <li>
          <router-link to="/">
            <v-icon> mdi-home </v-icon> Go To Website</router-link
          >
        </li>

        <li>
          <span @click="signOut"> <v-icon> mdi-power </v-icon> Sign Out</span>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  data: () => ({
    menuStatus: false,
    user: undefined,
    initials: undefined,
    menuDropdownStatus: false,

  }),
  methods: {
    ...mapActions(["logout", "getLogin"]),
    menuToggle() {
      this.menuDropdownStatus = !this.menuDropdownStatus;
    },
    signOut() {
      this.logout();
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapGetters(["allLogin"]),
  },
  created() {

    this.getLogin();
    if (this.allLogin) {
      this.user = this.allLogin.user;
      this.initials = this.user.fullname[0];
      if (this.user.fullname.split(" ").length > 1) {
        this.initials += this.user.fullname.split(" ")[1][0];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
header {
  position: relative;
  width: 100%;
  height: 100%;
}

.closeBtn {
  position: absolute;
  right: 0;
  width: 100%;
  text-align: right;
  padding: 10px 20px;

  .v-icon {
    color: #fff;
    cursor: pointer;
  }
}
.backBox {
  visibility: hidden;
  position: absolute;
  background: rgb(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
}
.menuBox {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  right: 0px;
  left: 0;
  z-index: 999;
  ul {
    list-style: none;
    padding-left: 0;
    padding-top: 10px;
    font-size: 14px;
    background: #0878d4;
  }

  li {
    width: 100%;
    padding: 13px 30px;
    a,
    span {
      color: #fff;
      text-decoration: none;
      display: flex;
      align-items: center;
      .v-icon {
        color: #fff;
        margin-right: 10px;
      }
    }
  }
}
.menuSlide {
  left: 0;
  transition: 0.3s all ease-in-out;
}
.backBoxOp {
  visibility: visible;
  right: 0;
}
.menu-dropdown {
  color: #fff;
  cursor: pointer;
  span {
    display: flex;
    align-items: center;
  }
  .v-icon {
    color: #fff;
  }
  li {
    padding: 13px 8px;
  }
}
.userInitials {
  text-transform: uppercase;
  font-size: 40px;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  padding: 0 30px;
  span {
    display: flex;
    height: 100px;
    width: 100px;
    border: 5px double #fff;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
  }
}
.userWelcome {
  margin-top: 35px;
  padding: 0 30px;
  font-size: 16px;
  text-transform: capitalize;
  color: #fff;
}
</style>