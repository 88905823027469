import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    color:undefined,
    colors:undefined,
});

const getters = {
    allColor:(state)=>state.color,
    allColors:(state)=>state.colors,
};

const actions = {
    async postColor({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}color/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setColor", response);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getColorList({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}color/colorList`, data,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setColors", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getColorListSelect({commit}){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}color/colorList`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setColors", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleColor({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}color/singleColor/${data}`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setColor", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getColorFrontend({commit},data){
        try {
            const response = await Axios.post(`${baseUrl}color/colorsFrontend`, data);
            if (response.status == 200) {
                commit("setColors", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteColor({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}color/singleColor/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setColors", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setColor: (state, data) => {
        return state.color = data;
    },
    setColors: (state, data) => {
        return state.colors = data;
    },
};

export default { state, getters, actions, mutations };
