import Axios from "axios";
import store from "..";
import { baseUrl, tokenHeader } from "../../router";
// import router from "../../router";

const state = () => ({
  product: undefined,
  property: undefined,
  properties: [],
  compareProducts: [],
  products: undefined,
  filters: undefined,
  searches: [],
  connectips: undefined,
  compareIds: [],
});

const getters = {
  allProduct: (state) => state.product,
  allProducts: (state) => state.products,
  allCompareProducts: (state) => state.compareIds,
  allProperty: (state) => state.property,
  allProperties: (state) => state.properties,
  allFilters: (state) => state.filters,
  allSearches: (state) => state.searches,
  allConnectIPS: (state) => state.connectips,
};

const actions = {
  async getConnectIPS({ commit }) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.get(
        `${baseUrl}payment/connectipstoken`,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setConnectIPS", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  addCompareProducts({ commit }, data) {
    commit("setCompareProducts", data);
    store.dispatch("setCompareIds", data);
  },
  async postProduct({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.post(
        `${baseUrl}product/create`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 201 || response.status == 200) {
        commit("setProduct", response.data);
        if (this.state.properties) {
          store.dispatch("postProperty");
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async postProperty({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      data.connectedTo = this.state.product.product.results._id;
      const response = await Axios.post(
        `${baseUrl}property/create`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 201 || response.status == 200) {
        commit("setProperty", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  setProperties({ commit }, data) {
    commit("setProperties", data);
  },
  async getProduct({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.get(
        `${baseUrl}product/getSingleProductWithToken/${data}`,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setProduct", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getProducts({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.post(
        `${baseUrl}product/productList`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setProducts", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getFrotendProducts({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined && localStorage.getItem("token")) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      let response;
      if (tokenHeader != undefined) {
        response = await Axios.post(
          `${baseUrl}product/allProductFrontend`,
          data,
          tokenHeader != undefined ? tokenHeader : tkHeader
        );
      } else {
        response = await Axios.post(
          `${baseUrl}product/allProductFrontend`,
          data
        );
      }

      if (response.status == 200) {
        commit("setProducts", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getProductSelects({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      let response = await Axios.post(
        `${baseUrl}product/productsSelects`,
        data,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setProducts", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleFrotendProduct({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined && localStorage.getItem("token")) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.get(
        `${baseUrl}product/getSingleProduct/${data}`,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setProduct", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getFilters({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}product/filter`, data);
      if (response.status == 200) {
        commit("setFilters", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getSearches({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}product/search`, data);
      if (response.status == 200) {
        commit("setSearches", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async deleteProduct({ commit }, data) {
    try {
      let tkHeader = {};
      if (tokenHeader == undefined) {
        tkHeader = {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("token")).token
            }`,
          },
        };
      }
      const response = await Axios.delete(
        `${baseUrl}product/singleProduct/${data}`,
        tokenHeader != undefined ? tokenHeader : tkHeader
      );
      if (response.status == 200) {
        commit("setProducts", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  setCompareIds({ commit }, data) {
    let _productCompare = [];
    let _compareIds = "";

    if (localStorage.getItem("compareproduct") != undefined) {
      let arrVal = JSON.parse(localStorage.getItem("compareproduct"));
      if (arrVal.length <= 3) {
        if (!arrVal.map((val) => val._id).includes(data._id)) {
          arrVal = [...arrVal, data];
        }
      }

      arrVal.forEach((ele, i) => {
        (_compareIds += i) == arrVal.length - 1 ? ele.id : ele.id + ",";
      });
      _productCompare = arrVal;

      localStorage.setItem("compareproduct", JSON.stringify(_productCompare));
    } else {
      localStorage.setItem("compareproduct", JSON.stringify([data]));
      _productCompare = [data];
    }
    commit("setCompareIds", _productCompare);
  },
  getCompareProducts({ commit }) {
    try {
      const productCompare = JSON.parse(localStorage.getItem("compareproduct"));
      commit("setCompareIds", productCompare);
    } catch (error) {
      console.log(error);
    }
  },
  deleteCompareProduct({ commit }, id) {
    let _compareIds = "";
    let arrVal = JSON.parse(localStorage.getItem("compareproduct"));

    arrVal = arrVal.filter((ele) => ele._id != id);
    console.log(arrVal);
    arrVal.forEach((ele, i) => {
      (_compareIds += i) == arrVal.length - 1 ? ele._id : ele._id + ",";
    });
    localStorage.setItem("compareproduct", JSON.stringify(arrVal));
    commit("setCompareIds", arrVal);
  },
};

const mutations = {
  setProduct: (state, data) => {
    return (state.product = data);
  },
  setProperty: (state, data) => {
    return (state.property = data);
  },
  setProperties: (state, data) => {
    return state.properties.push(data);
  },
  setProducts: (state, data) => {
    return (state.products = data);
  },
  setFilters: (state, data) => {
    return (state.filters = data);
  },
  setSearches: (state, data) => {
    return (state.searches = data);
  },
  setCompareProducts: (state, data) => {
    return state.compareProducts.push(data);
  },
  setConnectIPS: (state, data) => {
    return (state.connectips = data);
  },
  setCompareIds: (state, data) => {
    return (state.compareIds = data);
  },
};

export default { state, getters, actions, mutations };
