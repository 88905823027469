import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    menus: undefined,
    menusFooter: undefined,
    menu: undefined,
});

const getters = {
    allMenus: (state) => state.menus,
    allMenusFooter: (state) => state.menusFooter,
    allMenu: (state) => state.menu
};

const actions = {
    async postMenu({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}menu/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setMenus", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getMenus({ commit }) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}menu/menuList`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setMenus", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getMenu({ commit },data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}menu/singleMenu/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setMenu", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getFrontendMenus({ commit },data) {
        try {
            
            const response = await Axios.get(`${baseUrl}menu/menusFrontend/${data}`);
            if (response.status == 200) {
                if(data == 'header'){
                    commit("setMenus", response.data);
                }else{
                    commit("setMenusFooter", response.data);
                }
               
            }
        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setMenus: (state, data) => {
        return state.menus = data;
    },
    setMenusFooter: (state, data) => {
        return state.menusFooter = data;
    },
    setMenu: (state, data) => {
        return state.menu = data;
    },
};

export default { state, getters, actions, mutations };
