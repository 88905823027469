import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    address: undefined,
    addresses: undefined,
});


const getters = {
    allAddress: (state) => state.address,
    allAddresses: (state) => state.addresses,
};

const actions = {
    async postAddress({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}user/addAddress`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setAddress", response);
            }
        } catch (error) {
            console.log(error);
            commit("setAddress", error.response);
        }
    },
    async getAddresses({ commit },data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}user/addresses`, data,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setAddresses", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleAddress({ commit },data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}user/singleAddress/${data}`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setAddress", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteAddress({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}user/deleteAddress/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setAddresses", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setAddress: (state, data) => {
        return state.address = data;
    },
    setAddresses: (state, data) => {
        return state.addresses = data;
    },
};

export default { state, getters, actions, mutations };
