import Frontend from "../views/Frontend";
// import PrivacyPolicy from "../views/user/page/PrivacyPolicy.vue";
const frontendmenu = [
  {
    path: "/",
    component: Frontend,
    children: [
      {
        path: "/reviews",
        name: "Review",
        component: () =>
          import(/* webpackChunkName: "Review" */ "../views/user/page/Review.vue"),
      },
      {
        path: "/community",
        name: "Community",
        component: () =>
          import(/* webpackChunkName: "Community" */ "../views/user/page/Community.vue"),
      },
      {
        path: "/insurance",
        name: "Insurance",
        component: () =>
          import(/* webpackChunkName: "Insurance" */ "../views/user/page/Insurance.vue"),
      },
      {
        path: "/myvehicles",
        name: "MyVehicle",
        component: () =>
          import(/* webpackChunkName: "MyVehicle" */ "../views/user/account/MyVehicle.vue"),
      },
      {
        path: "/sellyourcar/:id",
        name: "UpdateSellYourCar",
        component: () =>
          import(/* webpackChunkName: "UpdateSellYourCar" */ "../views/user/account/SellYourVehicle.vue"),
      },
      {
        path: "/sellyourcar",
        name: "SellYourCar",
        component: () =>
          import(/* webpackChunkName: "SellYourCar" */ "../views/user/account/SellYourVehicle.vue"),
      },
      {
        path: "/chargingstations",
        name: "ChargingStation",
        component: () =>
          import(/* webpackChunkName: "ChargingStation" */ "../views/user/page/ChargingStation.vue"),
      },
      {
        path: "/years",
        name: "Year",
        component: () =>
          import(/* webpackChunkName: "Year" */ "../views/user/page/Year.vue"),
      },
      {
        path: "/bodytypes",
        name: "BodyType",
        component: () =>
          import(/* webpackChunkName: "BodyType" */ "../views/user/page/BodyType.vue"),
      },
      {
        path: "/brands",
        name: "Brand",
        component: () =>
          import(/* webpackChunkName: "Brand" */ "../views/user/page/Brand.vue"),
      },
      {
        path: "/blog/:id",
        name: "Page",
        component: () =>
          import(/* webpackChunkName: "Page" */ "../views/user/page/SingleBlog.vue"),
      },
      {
        path: "/blogs",
        name: "Blog",
        component: () =>
          import(/* webpackChunkName: "Blog" */ "../views/user/page/Blog.vue"),
      },
      {
        path: "/page/:slug",
        name: "Page",
        component: () =>
          import(/* webpackChunkName: "Page" */ "../views/user/page/Page.vue"),
      },
      {
        path: "/returnnrefund",
        name: "RefundnReturn",
        component: () =>
          import(/* webpackChunkName: "RefundnReturn" */ "../views/user/page/RefundnReturn.vue"),
      },
      {
        path: "/termsandcondition",
        name: "TermsnCondition",
        component: () =>
          import(/* webpackChunkName: "TermsnCondition" */ "../views/user/page/TermsnCondition.vue"),
      },
      {
        path: "/disclamer",
        name: "Disclamer",
        component: () =>
          import(/* webpackChunkName: "Disclamer" */ "../views/user/page/Disclamer.vue"),
      },
      {
        path: "/privacypolicy",
        name: "PrivacyPolicy",
        component: () =>
          import(/* webpackChunkName: "PrivacyPolicy" */ "../views/user/page/PrivacyPolicy.vue"),
      },
      {
        path: "/mypayment",
        name: "MyPayment",
        component: () =>
          import(/* webpackChunkName: "MyPayment" */ "../views/user/account/Mypayment.vue"),
      },
      {
        path: "/thankyou/:orderNumber",
        name: "Thankyou",
        component: () =>
          import(/* webpackChunkName: "Thankyou" */ "../views/user/page/Thankyou.vue"),
      },
      {
        path: "/checkout",
        name: "Checkout",
        component: () =>
          import(/* webpackChunkName: "Checkout" */ "../views/user/page/Checkout.vue"),
      },
      {
        path: "/transactionResponse/:response",
        name: "Transaction",
        component: () =>
          import(/* webpackChunkName: "Transaction" */ "../views/user/page/Checkout.vue"),
      },
      {
        path: "/addaddress/:id",
        name: "UpdateAddress",
        component: () =>
          import(/* webpackChunkName: "UpdateAddress" */ "../views/user/form/AddAddress.vue"),
      },
      {
        path: "/addaddress",
        name: "AddAddress",
        component: () =>
          import(/* webpackChunkName: "AddAddress" */ "../views/user/form/AddAddress.vue"),
      },
      {
        path: "/address",
        name: "Address",
        component: () =>
          import(/* webpackChunkName: "Address" */ "../views/user/account/Address.vue"),
      },
      {
        path: "/savedcars",
        name: "SavedCar",
        component: () =>
          import(/* webpackChunkName: "SavedCar" */ "../views/user/page/SavedCar.vue"),
      },
      {
        path: "/emicalculator",
        name: "EMICalculator",
        component: () =>
          import(/* webpackChunkName: "EMICalculator" */ "../views/user/page/EMICalculator.vue"),
      },
      {
        path: "/compare",
        name: "Compare",
        component: () =>
          import(/* webpackChunkName: "Compare" */ "../views/user/page/Compare.vue"),
      },
      {
        path: "/cart",
        name: "Cart",
        component: () =>
          import(/* webpackChunkName: "Acount" */ "../views/user/page/Cart.vue"),
      },
      {
        path: "/account",
        name: "Account",
        component: () =>
          import(/* webpackChunkName: "Acount" */ "../views/user/account/Account.vue"),
      },
      {
        path: "/changePassword",
        name: "ChangePassword",
        component: () =>
          import(/* webpackChunkName: "ChangePassword" */ "../views/user/account/ChangePassword.vue"),
      },
      {
        path: "/orders",
        name: "Order",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/user/account/Order.vue"),
      },
      {
        path: "/order/:id",
        name: "SingleOrder",
        component: () =>
          import(/* webpackChunkName: "SingleOrder" */ "../views/user/account/SingleOrder.vue"),
      },

      {
        path: "/ordercomplete",
        name: "OrderCompleted",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/user/account/OrderComplete.vue"),
      },

      {
        path: "/ordersprocess",
        name: "OrderProcess",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/user/account/OrderProcess.vue"),
      },
      {
        path: "/ordersreturn",
        name: "OrderReturn",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/user/account/OrderReturn.vue"),
      },
      {
        path: "/ordersexchange",
        name: "OrderExchange",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/user/account/OrderExchange.vue"),
      },

      {
        path: "/orderscancel",
        name: "OrderCancel",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/user/account/OrderCancel.vue"),
      },
      {
        path: "/product/:id",
        name: "Product",
        component: () =>
          import(/* webpackChunkName: "Product" */ "../views/user/page/SingleProduct.vue"),
      },

      {
        path: "/search",
        name: "Search",
        component: () =>
          import(/* webpackChunkName: "Search" */ "../views/user/page/FilterPage.vue"),
      },
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/user/page/Home"),
      },
      {
        path: "/registration",
        name: "RegistrationUser",
        component: () =>
          import(/* webpackChunkName: "RegistrationUser" */ "../views/user/form/Register"),
      },
      {
        path: "/login",
        name: "LoginUser",
        component: () =>
          import(/* webpackChunkName: "LoginUser" */ "../views/user/form/Login"),
      },
      {
        path: "/forgetPassword",
        name: "ForgetPasswordUser",
        component: () =>
          import(/* webpackChunkName: "ForgetPasswordUser" */ "../views/user/form/ForgetPassword"),
      },
      {
        path: "/forgetpasswordverify",
        name: "ForgetPasswordVerify",
        component: () =>
          import(/* webpackChunkName: "ForgetPasswordVerify" */ "../views/user/form/ForgetPasswordVerify"),
      },

      {
        path: "/taxcalculator",
        name: "TaxCalculator",
        component: () =>
          import(/* webpackChunkName: "TaxCalculator" */ "../views/user/form/TaxCalculator"),
      },
      {
        path: "/tobereviewed",
        name: "ToBeReviewed",
        component: () =>
          import(/* webpackChunkName: "ToBeReviewed" */ "../views/user/account/ToBeReviewed.vue"),
      },
      {
        path: "/reviewed",
        name: "Reviewed",
        component: () =>
          import(/* webpackChunkName: "Reviewed" */ "../views/user/account/Reviewed.vue"),
      },
      {
        path: "/contactus",
        name: "Contact",
        component: () =>
          import(/* webpackChunkName: "Contact" */ "../views/user/page/Contact.vue"),
      },
    ],
  },
];

export default frontendmenu;
