import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";

const state = () => ({
    page: undefined,
    pages: undefined,
});

const getters = {
    allPage: (state) => state.page,
    allPages: (state) => state.pages,
};

const actions = {
    async postPage({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}page/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setPage", response);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getPageList({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}page/pageList`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setPages", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSinglePage({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}page/singlePage/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setPage", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleFrontendPage({ commit }, data) {
        try {
  
            const response = await Axios.get(`${baseUrl}page/singleFrontPage/${data}`);
            if (response.status == 200) {
                commit("setPage", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getPageFrontend({ commit }, data) {
        try {
            const response = await Axios.post(`${baseUrl}page/pagesFrontend`, data);
            if (response.status == 200) {
                commit("setPages", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deletePage({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}page/singlePage/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setPages", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setPage: (state, data) => {
        return state.page = data;
    },
    setPages: (state, data) => {
        return state.pages = data;
    },
};

export default { state, getters, actions, mutations };
