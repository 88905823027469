<template>
  <section >
    <div class="offerCard">
      <div class="image">
        <router-link :to="`/product/${item.productId}`">
          <img
            :src="item.image"
            alt=""
          />
        </router-link>
      </div>
      <div class="offerDescription">
        <div>
          <router-link to="/business/testing"
            ><p class="offerTitle">{{item.productName}}</p></router-link
          >
          <span class="storename">Rs. {{item.productPrice}}</span>
          <span class="variantSelection" 
            >Quantity : {{item.quantity}}</span
          >
        </div>
        <div>
          <v-icon @click="removeToCart(item._id)" v-if="!loading"> mdi-delete-outline </v-icon>
          <v-progress-circular indeterminate v-if="loading"></v-progress-circular>
        </div>
      </div>
    </div>
    <div class="desc" v-if="info">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum
        purus sem arcu luctus commodo. Quis at aliquam ullamcorper lacus.
      </p>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CartCard",
  props: ["info","item"],
  data: () => ({
    addedToCart: false,
    valueCart: 0,
    loading:false
  }),
  methods: {
    ...mapActions(["deleteCart"]),
    async removeToCart(id) {
      this.loading = true;
      await this.deleteCart(id);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 20px;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.offerCard {
  display: flex;
  justify-content: flex-start;
  .image {
    width: 80px;
    height: 80px;
    background: gray;
    border-radius: 10px;
    img {
      object-fit: cover;
    width: 80px;
    height: 80px;
      border-radius: 8px;
    }
    margin-right: 10px;
  }
  .offerDescription {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 15px;
    .offerTitle {
      color: rgba(26, 26, 26, 1);
      font-weight: 700;
      font-size: 180x;
      text-transform: capitalize;
      text-align: left;
    }
    .price {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      p {
        font-size: 14px;
      }
    }
  }
  .v-btn {
    font-size: 13px;
    height: 50px;
    width: 130px;
    .v-icon {
      font-size: 16px;
      margin-left: 5px;
    }
    background: #0878d4!important;
    color: #fff;
  }
}
.text-red {
  color: rgba(197, 46, 127, 1);
  font-weight: 700;
}
.rating {
  margin-bottom: 5px;
  margin-left: -5px;
}
.salePrice {
  position: relative;
  text-decoration: line-through;
  &::after {
    position: absolute;
    text-decoration: none;
  }
}
.storename {
  color: #8c8c8c;
  font-size: 14px;
  text-align: left;
  display: block;
  margin-top: 5px;
}
.status.on {
  color: green;
  font-size: 14px;
}

.variantSelection {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  display: block;
  text-align: left;
}
.borderBtn {
  border: 1px solid #b3b3b3;
  padding: 3px;
  border-radius: 5px;
}
.actionBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;
  padding-left: 0;
  li {
    margin: 0;
    &:nth-of-type(2) {
      width: 40px;
      text-align: center;
    }
  }
  .v-icon {
    font-size: 22px;
  }
}
.desc {
  margin-top: 10px;
  p {
    font-size: 14px;
    color: #8c8c8c;
  }
}
.v-icon{
  background: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}
</style>