<template>
  <section>
    <v-main>
      <Header />
      <router-view :key="$route.fullPath" class="bodySection"></router-view>
      <Footer />
    </v-main>
  </section>
</template>

<script>
import Header from "@/components/user/Header";
import Footer from "@/components/user/Footer";
export default {
  name: "Frontend",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>

</style>