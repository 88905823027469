import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Croppa from "vue-croppa";
import VueApexCharts from 'vue-apexcharts'
import store from './store'
import VueMoment from 'vue-moment';
import CKEditor from 'ckeditor4-vue';

Vue.use( CKEditor );

Vue.use(VueMoment)

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)


Vue.use(Croppa);
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')



