import Axios from "axios";
import { baseUrl, tokenHeader } from "../../router";
// import router from "../../router";

const state = () => ({
    advertisement: undefined,
    advertisements: undefined,
});

const getters = {
    allAdvertisement: (state) => state.advertisement,
    allAdvertisements: (state) => state.advertisements,
};

const actions = {
    async postAdvertisement({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}advertisement/create`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 201 || response.status == 200) {
                commit("setAdvertisement", response);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getAdvertisement({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.post(`${baseUrl}advertisement/advertisementList`, data, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setAdvertisements", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getSingleAdvertisement({commit},data){
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.get(`${baseUrl}advertisement/singleAdvertisment/${data}`,tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setAdvertisement", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async getFrontendAdvertisements({ commit }, data) {
        try {
           
            const response = await Axios.post(`${baseUrl}advertisement/advertisementFrontend`, data);
            if (response.status == 200) {
                commit("setAdvertisements", response.data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async deleteAdvertisements({ commit }, data) {
        try {
            let tkHeader = {};
            if (tokenHeader == undefined) {
                tkHeader = {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token")).token}`
                    }
                };
            }
            const response = await Axios.delete(`${baseUrl}advertisement/singleAdvertisement/${data}`, tokenHeader != undefined ? tokenHeader : tkHeader);
            if (response.status == 200) {
                commit("setAdvertisements", response.data);
            }

        } catch (error) {
            console.log(error);
        }
    },
}

const mutations = {
    setAdvertisement: (state, data) => {
        return state.advertisement = data;
    },
    setAdvertisements: (state, data) => {
        return state.advertisements = data;
    },
};

export default { state, getters, actions, mutations };
